






import { FormMutate } from "@/components/TruckingSalesOrder";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    FormMutate,
  },
})
export default class EditPage extends Vue {}
